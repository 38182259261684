import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"


function TermsOfuse () {

  return (
    <Layout>

    <section class="static-content">
      <div className="container">
        <div className="row">
            <div className="col-lg-8 offset-lg-2 col-12">
                <h1>iamcore Website Terms of Use</h1>
                <p>
                These Terms of Use (“Terms”) govern all access and use of IAMCORE LLC (“iamcore”, “Us”, “Our”, or “We”) websites located at iamcore.io (“Sites”), online products, services, materials, software, and the documents, images, and other content accessible through Our Sites (“Content”). These Terms form a contract between You and iamcore LLC. The terms “You” and “Your” refer to the user or viewer of the Sites.
                </p>
                <p>
                BY VISITING, USING, ACCESSING, OR BROWSING THE SITES OR CONTENT YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH IAMCORE, THAT YOU HAVE READ, UNDERSTOOD, AND WILL COMPLY WITH THE TERMS, AND AGREE TO BE BOUND BY THESE TERMS. PLEASE READ THESE TERMS AS WELL AS OUR PRIVACY POLICY AT <Link to="/privacy-policy">iamcore.io/privacy-policy</Link>. IF YOU ARE AN INDIVIDUAL ACTING ON BEHALF OF ANY COMPANY, BUSINESS, OR ENTITY, YOU REPRESENT THAT YOU ARE AUTHORIZED TO ACCEPT THESE TERMS ON ITS BEHALF. 
                </p>
                <p>
                IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, YOU SHOULD NOT USE OR ACCESS THE SITES OR CONTENT.
                </p>

                <h2>Modifications</h2>
                <p>
                We reserve the right to modify these Terms and Conditions at any time, at Our sole discretion. We will post modifications of the Terms and Conditions on the Sites and other places We deem appropriate. If We make material changes to these Terms and Conditions, We will notify you here, by email, or through a notice on Our home page. Your continued use or access of the Sites or Content following any changes to these Terms and Conditions shall constitute Your acceptance of the changes and the revised Terms.
                </p>
                <h2>Use of the Sites</h2>
                <p>
                You may use the Sites solely for their intended purposes. All Content, including, but not limited to, text, graphics, designs, logos, interfaces, and trademarks are property of iamcore. Such Content may be viewed, printed, or downloaded for personal, non-commercial purposes, provided that the Content is not modified in any way and all logos remain clearly visible. Such Content may not be published, distributed, or displayed without the express written consent of iamcore. We retain all rights to such Content, and does not grant any express or implied license to use the intellectual property of iamcore or its licensors in any way, except as stated in these Terms.
                </p>
                <h2>Other Products and Services</h2>
                <p>
                We may offer products and services through the Sites that are subject to their own terms and conditions. These Terms do not alter those agreements, and if there is a conflict between these Terms and the applicable agreement, the terms of the applicable agreement will govern. The Sites may include links to third party websites. We are not responsible for the content of third-party websites, which may have their own terms and conditions and privacy policies.
                </p>
                <h2>User Accounts</h2>
                <p>
                Certain features of the Sites or Content may require that You register by providing Personal Data, such as Your email address. 
                </p>
                <p>
                You agree that the information You provide is accurate, and that You are not prohibited by law from registering. Users in the European Union who are under the age of sixteen (16), and users in the United States under the age of thirteen (13), are not permitted to provide Personal Data or create an account with Us. If We believe that a registration has been created by an individual who is under the minimum age in their jurisdiction, all information received by such individual shall be promptly deleted.
                </p>
                <h2>Feedback</h2>
                <p>
                You have no obligation to give Us any suggestions, comments, or other feedback relating to the Sites or Content (“Feedback”). If you provide Us with Feedback, you grant Us a worldwide, royalty-free, non-exclusive, perpetual, and irrevocable license to use, copy, modify, sublicense, and otherwise utilize the Feedback (including any ideas, concepts, methods, know-how, or techniques embodied in Feedback) for any purpose, without any restriction or obligation to you based on intellectual property rights or otherwise. You accept full responsibility for Your Feedback, and warrant that You have the exclusive right to submit such Feedback, that it does not violate any third party intellectual property rights, does not violate any applicable laws, and that its use, reproduction, or distribution will not give rise to any type of civil or criminal liability. Under no circumstances shall You be entitled to reimbursement, compensation, or consideration of any kind from iamcore for Your Feedback.
                </p>
                <h2>Communicating with You</h2>
                <p>
                We may contact You by email or other means provided when you register with us or sign up for our products or services. You may unsubscribe or modify Your preferences regarding how We communicate with You at any time in Your Account Settings by following the “unsubscribe” link located at the bottom of Our marketing and newsletter emails, or by contacting Us at privacy@iamcore.io. For more information see the Privacy Policy.
                </p>

                <h2>Your Content and Contributions</h2>
                <p>
                You agree that Your contributions, comments, suggestions, or improvements, or contributions made by You (“Contributions”) may be used, reproduced, distributed, made available for public display, or used to create derivative works by iamcore. You grant to iamcore a perpetual and irrevocable license to use Your Contributions for any purpose, and in any medium, worldwide. You accept full responsibility for Your Contributions, and warrant that You have the exclusive right to submit such Contributions, that they does not violate any third party intellectual property rights, that they do not violate any applicable laws, and that their use, reproduction or distribution will not give rise to any type of civil or criminal liability. Under no circumstances shall You be entitled to reimbursement, compensation, or consideration of any kind from iamcore for Your Contributions.
                </p>

                <h2>Privacy</h2> 
                <p>
                <Link to="/privacy-policy">The iamcore Privacy Policy</Link> is incorporated into these Terms and applies to all use of the Sites and Content.</p>
                <h2>Termination</h2>
                <p>
                WE have the right to take down all or parts of the Sites or Content, or to revoke Your access and to prohibit Your use of the Sites or Content with or without cause, and without notice.
                Indemnification
                </p>
                <p>
                You (or if you are using the Sites on behalf of a company, business, or entity, You and the entity) agree to defend, indemnify, and hold iamcore, its officers, directors, employees, agents, affiliates, assignees, and successors harmless from and against any claims, liabilities, damages, losses, and expenses (including, without limitation, reasonable legal fees, costs, and expenses) arising out of or in any way connected with Your use of the Sites or Content, Your violation of these Terms, Your violation of any law, or of any third party rights, including but not limited to intellectual property rights of third parties.
                </p>
                <h2>Disclaimers</h2>
                <p>
                THE SITES AND CONTENT ARE PROVIDED ON AN “AS IS” BASIS WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, THAT THE INFORMATION PROVIDED IS ACCURATE, THAT THE SITES AND CONTENT WILL BE UNINTERRUPTED OR ERROR FREE, THAT THEY WILL MEET YOUR REQUIREMENTS, THAT THEY WILL PRODUCE CERTAIN RESULTS, THAT THEY ARE ERROR-FREE OR FREE OF VIRUSES.
                </p>
                <p>
                YOU EXPRESSLY AGREE THAT YOU USE THE SITES AT YOUR SOLE RISK, AND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY HARM OR DAMAGE OF ANY KIND THAT MAY RESULT FROM YOUR USE OF THE SITES OR ANY LINKED THIRD PARTY SITES. YOU AGREE THAT YOU ARE COMPLETELY RESPONSIBLE FOR YOUR USE OF THE SITES, AND THAT YOUR SOLE REMEDY AGAINST IAMCORE RELATED TO USE OF THE SITES SHALL BE TO DISCONTINUE USE. THIS SECTION SHALL SURVIVE TERMINATION OF THESE TERMS OR OF YOUR USE OF THE SITES.
                </p>
                <h2>Limitation of Liability</h2>
                <p>
                IN NO EVENT WILL IAMCORE, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES, SUCCESSORS, OR ASSIGNEES BE RESPONSIBLE FOR ANY DAMAGES, INCLUDING WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES AS WELL AS LOST PROFITS ARISING OUT OF THE USE, INABILITY TO USE THE SITES, OR ANY OTHER SERVICES OR CONTENT.
                </p>
                <p>
                ADDITIONALLY YOU ACKNOWLEDGE AND AGREE THAT NEITHER IAMCORE, NOR ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, SUCCESSORS, OR ASSIGNEES SHALL BE LIABLE, REGARDLESS OF THE CAUSE OR DURATION, FOR ANY ERRORS, OMISSIONS, LOSSES OF DATA, VIRUSES, OR DELAYS IN TRANSMISSION OF DATA ARISING FROM USE OF THE SITES, ITS CONTENT, AND ANY PRODUCTS OR SERVICES AVAILABLE THROUGH THE SITES OR ANY LINKED THIRD PARTY SITES. THESE LIMITATIONS SHALL APPLY TO ALL COMPUTERS, SERVERS, DEVICES, CHIPS, AND OTHER EQUIPMENT OR PERIPHERALS THAT MAY BE AFFECTED.
                </p>
                <p>
                THESE LIMITATIONS SHALL APPLY TO THE EXTENT ALLOWED BY APPLICABLE LAW.
                </p>
                <h2>Governing Law</h2>
                <p>
                These Terms shall be subject to the laws of the State of New York, without regard to conflict of law provisions, and shall govern any disputes arising between You and iamcore, its officers, directors, employees, agents, affiliates, successors, or assignees. In case of any dispute between You and iamcore, You consent to exclusive venue and jurisdiction in state and federal courts located in the Borough of Manhattan in the City of New York.
                </p>
                <p>
                You waive any right to trial by jury and any defenses relating to venue and jurisdiction, to the extent permissible by law, in regard to disputes arising from use of the Sites or the Terms.
                </p>
                <h2>Export Compliance</h2>
                <p>
                You agree to comply with all laws and restrictions controlling the export of products and information of The United States or of foreign authorities, and to not directly or indirectly export or re-export the products or Services in violation of any rules, restrictions, regulations, laws, acts, or trade sanctions.
                </p>

                <h2>General</h2>
                <p>
                These Terms and the <Link to="/privacy-policy">Privacy Policy</Link> referenced herein constitute the entire agreement between You and iamcore, govern Your access and use of the Sites, and supersede any prior agreements between You and iamcore. If any provision of this agreement is held void or unenforceable, the remaining terms shall remain in full force and effect.
                </p>


            </div>
        </div>
      </div>
    </section>

  </Layout>
  )
}

export const Head = () => <Seo title="Terms of use" description="Take advantage of just how easy and flexible authorization may be." />

export default TermsOfuse
